<template>
  <div class="login-container">
    <div class="lianhua-box">
      <div class="lianhua-img-box">
        <svg-icon icon-class="lianhua" class="lianhua-img"></svg-icon>
      </div>
    </div>
    <div class="form-box">
      <van-form>
        <van-field
          v-model="tel"
          clearable
          label-width="10"
          type="number"
          left-icon="smile-o"
          placeholder="请输入手机号"
          name="asyncValidator"
          size="large"
          maxlength="11"
          :leftIcon="require('@/assets/admin.png')"
        >
        </van-field>
        <van-field
          v-model="code"
          type="number"
          clearable
          label-width="10"
          left-icon="smile-o"
          placeholder="请输入验证码"
          name="asyncValidator"
          maxlength="6"
          :leftIcon="require('@/assets/psw.png')"
        >
          <template #button>
            <button v-if="!isClumnDown" class="sendcode-btn" :disabled="isAbleBtn" @click="sendCode">发送验证码</button>
            <!--            <van-button ref="codeBtn" type="default" round size="mini" disabled v-if="!isClumnDown" @click="sendCode">发送验证码</van-button>-->
            <!--            <div v-if="!isClumnDown" @click="sendCode" class="send-code">-->
            <!--              发送验证码-->
            <!--            </div>-->
            <div v-else>
              <div
                v-if="isFinish"
                @finish="changeStatus"
                @click="sendCode"
                class="re-send-code"
              >
                重新发送
              </div>
              <div v-else>
                <van-count-down
                  :time="time"
                  format="ss 秒"
                  @finish="finish"
                  class="time-text"
                />
              </div>
            </div>
          </template>

        </van-field>
      </van-form>
    </div>
    <div @click="goToLogin" class="submit-btn">
      <!--      <van-button ref="commitBtn"-->
      <!--                  type="default"-->
      <!--                  size="large"-->
      <!--                  round-->
      <!--                  disabled @click="goToLogin">确认登录-->
      <!--      </van-button>-->
      <button class="commit-btn"
              :disabled="isCommitBtn"
      >确认登录
      </button>
    </div>
    <!--    <div class="back-home" @click="backHome">-->
    <!--      <img :src="back" alt="" class="back-home-png" />-->
    <!--    </div>-->
    <!-- <div class="submit-msg-box">
      <img :src="submit" alt="" class="submit-png" />
      <span class="proxy-msg">用户协议</span>
      <span class="proxy-content">未注册用户将自动注册</span>
    </div> -->
    <!-- {{ userd }}
    <hr />
    {{ useri }} -->
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { SendValidCode, userLogin ,getTest} from '@/api/modules/login'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'LoginIndex',
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {
      tel: '',
      code: '',
      isClumnDown: false,
      time: 61 * 1000,
      isFinish: false,
      RegisterTitle: '登陆注册',
      resUrl: '',
      userd: localStorage.getItem('userAccount') || 178,
      useri: localStorage.getItem('userTelInfo') || 32,
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('user', ['userInfo', 'openId', 'alicode']),
    isAbleBtn () {
      return this.tel.length !== 11
    },
    isCommitBtn () {
      return !(this.tel.length === 11 && this.code.length === 6)
    }
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    ...mapMutations('user',['setUserInfo']),
    changeStatus () {
      this.isClumnDown = true
      this.isFinish = false
    },
    // 密码验证
    ValidCode (val) {
      return /^\d{6}$/.test(val)
    },
    // 手机号验证
    ValidatorPhoneNum (phoneNumber) {
      // 定义手机号的正则表达式
      const phoneRegex = /^1[3456789]\d{9}$/
      // 使用正则表达式进行验证
      return phoneRegex.test(phoneNumber)
    },
    //获取验证码
    async sendCode () {
      // 验证手机号
      if (!this.ValidatorPhoneNum(this.tel)) {
        this.$toast('请输入正确的手机号！')
        return
      }

      if (this.tel.trim) {
        this.$toast.loading({
          message: '加载中...',
          duration: 2000
        })
        // 延迟两秒后发送请求
        setTimeout(async () => {
          try {
            const data = await SendValidCode(this.tel)
            if (data.code === 200) {
              this.$toast('发送成功')
              this.isClumnDown = true
              this.isFinish = false
            } else {
              this.$toast({
                message: data.message
              })
            }
          } catch (error) {
            console.error('发送请求出错:', error)
          }
        }, 2000)
      } else {
        this.$toast.fail({
          message: '请输入正确的手机号!',
          forbidClick: true,
          duration: 2000,
        })
      }
    },

    // 用户登陆注册
    async goToLogin () {
      if (!this.tel.trim() || !this.code) {
        return
      }
      const res =  await userLogin({
        account: this.tel,
        validCode: this.code,
        openId: this.$store.state.user.openId || ''
      })
      if(res.code === 200){
        //登录成功
        this.setUserInfo(res.data)
        this.$router.go(-1)
      }else{
        this.$toast.fail({
          message: res.message
        })
      }
    },

    // 倒计时结束
    finish () {
      this.isFinish = true
    },
  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.login-container {
  width: 100vw;
  height: 100vh;
  background: #FFFFFF;

  .time-text {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bc8d4a;
    line-height: 20px;
  }

  .re-send-code {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bc8d4a;
    line-height: 20px;
  }

  .send-code {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bc8d4a;
    line-height: 20px;
  }

  .proxy-content {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 17px;
  }

  .proxy-msg {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  .submit-png {
    position: relative;
    top: 3px;
    height: 17px;
  }

  .submit-msg-box {
    text-align: center;
    margin-top: 12px;
  }

  .submit-btn {
    padding: 0 28px;
    display: flex;
    justify-content: center;
  }

  .form-box {
    margin: 0 27px 46px 27px;
  }

  .lianhua-img {
    width: 80px;
    height: 50px;
    padding: 10px;
    background-size: 100% 100%;
  }

  .lianhua-img-box {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #c34e2a 0%, #972923 100%);
    border-radius: 10px;
    //box-shadow: 0 0 20px 5px rgba(255, 215, 0, 0.7), 0 0 30px 10px rgba(255, 215, 0, 0.5), 0 0 40px 15px rgba(255, 215, 0, 0.3), 0 0 50px 20px rgba(255, 215, 0, 0.1);
    box-shadow: 0 0 15px 5px rgba(216, 171, 125, 0.5);
  }

  .lianhua-box {
    width: 375px;
    height: 252px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.sendcode-btn {
  width: 80px;
  height: 25px;
  border-radius: 15px;
  font-size: 12px;
  color: #972923;
  border: 1px solid #972923;
}

.commit-btn {
  width: 290px;
  height: 50px;
  border-radius: 25px;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #972923;
  background-color: #c34e2a;
}

button:disabled {
  opacity: 0.3;

}


:deep .van-field__left-icon {
  display: flex;
  align-items: center;
}

:deep .van-cell {
  border-bottom: 1px solid #EDEDED !important;
}

:deep .van-field__button {
  display: flex;
  align-items: center;
}

:deep .van-button__text {
  font-size: 12px;
  //color: #972923;

}
</style>
